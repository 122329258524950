import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'ui-kit/container';
import Image from 'ui-kit/image';
import Intro from 'ui-kit/intro';
import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import Button from 'ui-kit/button';
import { Headline, Highlight, P } from 'ui-kit/typography';
import { getImageFromNode } from 'helpers/utils';

import Layout from '../../components/layout';
import TeaserSection from '../../components/teaser-section';

const IngredientsAndAllergensPage = ({ data }) => {
    /** Bilder */
    const stageImage = data.stageImage.childImageSharp;
    const { contentImages } = data;

    return (
        <Layout description="Zutaten und Allergene">
            <Stage
                image={stageImage}
                imageAlt="Kinderhand hält einen Strauß Wildblumen"
                tag="Kontakt & Service | Zutaten & Allergene"
                headline={{ text: 'Wir helfen Ihnen gerne weiter', level: 'h1' }}
                bottomShade
            />
            <Section>
                <Intro
                    title="Zutaten & Allergene - Genuss mit gutem Gewissen"
                    cta={
                        <>
                            <Highlight gap="xl">Sie sehen das auf einen Blick.</Highlight>
                            <br />
                            <Button linkTo="/flippingbook/allergene/index.html">
                                Broschüre &quot;Allergenfreier Genuss&quot;
                            </Button>
                        </>
                    }
                >
                    Wenn‘s um die Wurst geht, entscheidet der Geschmack. Und damit jeder unsere
                    köstlichen Produkte genießen kann, legen wir größten Wert auf qualitativ
                    hochwertige Zutaten und arbeiten soweit möglich allergenfrei. Auf den
                    Verpackungen unserer Markenprodukte sind alle Inhaltsstoffe übersichtlich
                    aufgelistet. Da steckt nur Gutes für Sie drin -
                </Intro>
            </Section>
            <Section bg="gray">
                <Container size="l" gap="xxxxl" grid layout="6-6" cols="2" alignItems="center">
                    <Image
                        {...getImageFromNode(
                            contentImages,
                            'ingredients-and-allergens-page/inhaltsbild-glutenfrei.jpg'
                        ).node.childImageSharp}
                        alt="Weizenähre in Nahaufnahme"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                    <div>
                        <Headline level="h3">Glutenfrei</Headline>
                        <P gap="l">
                            Gluten, auch Klebereiweiß genannt, ist ein natürlicher Bestandteil
                            verschiedener Getreidesorten wie Weizen, Dinkel, Roggen, Gerste oder
                            Hafer und deshalb in vielen Lebensmitteln – zum Teil auch nur in Spuren
                            – enthalten. Wer an Glutenunverträglichkeit (Zöliakie) leidet, muss
                            glutenhaltige Lebensmittel konsequent vermeiden und auch beim Kauf von
                            Wurstwaren ausschließlich auf glutenfreie Produkte achten. Unsere
                            glutenfrei gekennzeichneten Produkte von DonauLand und vielLeicht bieten
                            unbeschwerten Genuss für alle.
                        </P>
                    </div>
                </Container>
                <Container
                    size="l"
                    gap="xxxxl"
                    grid
                    layout="6-6"
                    cols="2"
                    alignItems="center"
                    reverseOnMobile
                >
                    <div>
                        <Headline level="h3">Laktosefrei</Headline>
                        <P gap="l">
                            Laktose, bzw. Milchzucker ist in Milch und Milchprodukten enthalten.
                            Auch viele weitere Lebensmittel, die Anteile von Milch aufweisen, sind –
                            wenn auch in geringerem Umfang – laktosehaltig. Etwa 75 % der
                            erwachsenen Weltbevölkerung leiden an Laktoseintoleranz. In Deutschland
                            liegt der Anteil bei rund 20 %. Wer keine Laktose verträgt, sollte nicht
                            nur auf entsprechende Milchprodukte verzichten, sondern auch beim
                            Wurstkauf auf laktosefreie Produkte zurückgreifen. Wir machen es Ihnen
                            leicht. Denn dank unseren vielen, klar gekennzeichneten laktosefreien
                            Wurstspezialitäten braucht niemand auf das einzigartige
                            Geschmackserlebnis zu verzichten.
                        </P>
                    </div>
                    <Image
                        {...getImageFromNode(
                            contentImages,
                            'ingredients-and-allergens-page/inhaltsbild-laktosefrei.jpg'
                        ).node.childImageSharp}
                        alt="Krug und Glas mit Milch"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                </Container>
                <Container size="l" gap="xxxxl" grid layout="6-6" cols="2" alignItems="center">
                    <Image
                        {...getImageFromNode(
                            contentImages,
                            'ingredients-and-allergens-page/inhaltsbild-geschmacksverstaerkerfrei.jpg'
                        ).node.childImageSharp}
                        alt="Mehrere Schälchen mit natürlichen Gewürzen"
                        layer={1}
                        gap={{ small: 'xxl', medium: 'none' }}
                    />
                    <div>
                        <Headline level="h3">Frei von Geschmacksverstärkern</Headline>
                        <P gap="l">
                            Geschmacksverstärker sind chemische, aber auch natürliche Stoffe, die
                            den Geschmack von Nahrungsmitteln verbessern oder intensivieren sollen.
                            Wir haben unsere Rezepturen so verfeinert, dass wir auf
                            Geschmacksverstärker wie auch auf Hefeextrakt verzichten können. Wir
                            setzen voll auf den natürlichen Geschmack unserer Fleisch- und
                            Wurstwaren. Wer qualitativ hochwertige Rohstoffe und Zutaten nach
                            traditionellen Rezepten verarbeitet, braucht keine Geschmacksverstärker.
                            Und darüber freuen sich nicht nur Allergiker.
                        </P>
                    </div>
                </Container>
            </Section>
            <TeaserSection boxes={['originAndQuality', 'recipes']} bg="white" />
        </Layout>
    );
};

IngredientsAndAllergensPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        contentImages: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(
            relativePath: { eq: "service-and-contact-pages/buehne-service-und-kontakt.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        contentImages: allFile(
            filter: { relativePath: { glob: "ingredients-and-allergens-page/inhaltsbild-*.jpg" } }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 605, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default IngredientsAndAllergensPage;
