import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import { gapable, layerable } from 'helpers/traits';
import { colors } from 'helpers/variables';

/**
 * Wrappt Gatsby-Images
 * @param {string} props.className Optional: Klasse für erweitertest Styling
 * @param {string} props.hasBorder Optional:
 * @param {object} props Gatsby Image Props
 */
const Image = styled(({ className, hasBorder, ...props }) => (
    <GatsbyImage className={className} {...props} />
))`
    ${({ hasBorder }) => hasBorder && `border: 1px solid ${colors.gray300};`};
    width: 100%;
    max-width: 100%;
    ${gapable()};
    ${layerable()};
`;

Image.propTypes = {
    className: PropTypes.string,
    hasBorder: PropTypes.bool,
};

Image.defaultProps = {
    className: null,
    hasBorder: null,
};

export default Image;
